import React from "react"

import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { useImage } from "../../hooks/useImage"
import { useLanguage } from "../../hooks/useLanguage"
import { useSanity } from "../../hooks/useSanity"

export const withArticle = Component => ({ name = `Article`, page }) => {
  const {
    config: {
      settings: { lists, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()
  const { getFluidImage } = useImage()
  const locales = useLanguage(`blog`)
  const { textNormaliser, rawContentNormaliser, richTextNormaliser } = useSanity()

  const content = {
    ...page,
    type: "article",
    content: rawContentNormaliser(page),
    title: textNormaliser(page.title),
    titleText: richTextNormaliser(page.title),
    categories: page?.categories?.length > 0 ? page.categories.map(category => category.title) : [],
  }
  const image = getFluidImage(content?.featuredImage)
  const paths = [
    {
      title: locales.breadcrumb,
      url: routes.ARTICLE,
    },
    {
      title: content.titleText ? content.titleText.split(":")[0] : "",
      url: isBrowser ? window.location.pathname : null,
    },
  ]

  Component.displayName = name
  return <Component content={content} image={image} lists={lists} paths={paths} />
}
