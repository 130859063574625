import React from "react"
import { graphql } from "gatsby"

import { Article as Template } from "../components/Article/Article"

export const pageQuery = graphql`
  query($id: String) {
    page: sanityArticle(_id: { eq: $id }) {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      _rawContent(resolveReferences: { maxDepth: 10 })
      _createdAt
      author {
        name
      }
      categories: _rawCategories(resolveReferences: { maxDepth: 10 })
      content {
        ... on SanityImageType {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 800) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanityImageLinkType {
          _key
          image {
            _type
            alt
            asset {
              fluid(maxWidth: 800) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      title: _rawTitle(resolveReferences: { maxDepth: 10 })
      publishedAt(formatString: "DD.MM.YYYY")
      featuredImage: _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Template {...props} {...data} />
